import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "./common/Header";
import Footer from "./common/Footer";
import MainRoutes from "./routes/MainRoutes";
import BidFrom from "./components/biding";
import { useLocation } from "react-router-dom";
import ApiService from "./utils/Api";
import { GET_CONSTANTS } from "./utils/Endpoint";

function App() {
  const location = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useState(
    sessionStorage.getItem("auth") ? true : false
  );

  useEffect(() => {
    setIsAuthenticated(sessionStorage.getItem("auth") ? true : false);
  }, []);

  useEffect(() => {
    getConstantData();
  }, []);

  const getConstantData = async (data) => {
    try {
      const response = await ApiService.post(`${GET_CONSTANTS}`, data);
      if (response.status === 200) {
        const value = response.data.response;
        if (Array.isArray(value) && value.length > 0) {
          localStorage.setItem("driver_radius", value[43]?.cvalue);
          localStorage.setItem("rl_driver_radius", value[0]?.cvalue);
        }
      }
    } catch (error) {
      console.log("User fetch ErrorError::", error);
    }
  };

  return (
    <>
      {!["/schedule-form", "/booking-complete"].includes(location.pathname) && (
        <Header
          isAuthenticated={isAuthenticated}
          setIsAuthenticated={setIsAuthenticated}
        />
      )}
      {/* Pass setIsAuthenticated to the Header component */}
      {!["/schedule-form", "/booking-complete"].includes(location.pathname) && (
        <BidFrom />
      )}
      <div className="App">
        <MainRoutes setIsAuthenticated={setIsAuthenticated} />{" "}
        {/* Pass setIsAuthenticated to the MainRoutes component */}
        <ToastContainer />
      </div>
      {!["/schedule-form", "/booking-complete"].includes(location.pathname) && (
        <Footer />
      )}
      {/* Include the Footer component */}
    </>
  );
}

export default App;
